const state = {
  loading: false,
  shyLoading: false,
  pendingRequests: 0
}

const mutations = {
  setLoader(state, isLoading) {
    state.loading = isLoading
  },
  setShyLoader(state, isLoading) {
    state.shyLoading = isLoading
  },
  setPending(state) {
    if (state.pendingRequests === 0) {
      state.loading = true
    }

    state.pendingRequests++
  },
  setDone(state) {
    if (state.pendingRequests >= 1) {
      state.pendingRequests--
    }

    if (state.pendingRequests <= 0) {
      state.loading = false
      state.shyLoading = false
    }
  }
}

const actions = {
  stopLoader({ commit }) {
    commit('setShyLoader', false)
    commit('setDone')
  },
  startLoader({ commit }, payload) {
    if (payload?.shy) {
      commit('setShyLoader', true)
      return
    }
    commit('setPending')
  },
  stopAllLoaders({ commit }) {
    commit('setLoader', false)
  }
}

export const loading = {
  namespaced: true,
  state,
  actions,
  mutations
}
