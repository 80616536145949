import ApiService from '@/api/new/services/api.service'
import ApiServiceV2 from '@/api/new/services/v2/api.service'

import i18n from '@/i18n'

const actions = {
  async createProject({ dispatch, commit, rootGetters, rootState }, payload) {
    const data = new FormData()
    data.append('data[type]', 'projects')
    data.append('data[attributes][source_language]', payload.source)
    data.append('data[attributes][workflow_id]', payload.workflow_id)

    if (payload.projectName) {
      data.append('data[attributes][name]', payload.projectName)
    }
    if (payload.deadline) {
      data.append('data[attributes][preferred_deadline]', payload.deadline)
    }
    if (payload.folderId) {
      data.append('data[attributes][folder_id]', payload.folderId)
    }
    if (payload.instructions) {
      data.append(
        'data[attributes][customer_instructions]',
        payload.instructions
      )
    }
    for (let i = 0; i < payload.targets.length; i++) {
      data.append('data[attributes][target_languages][]', payload.targets[i])
    }
    for (let y = 0; y < payload.files.length; y++) {
      data.append('data[attributes][files][]', payload.files[y])
    }
    await ApiServiceV2.postFormData(
      `teams/${rootGetters['workspace/currentAccountId']}/projects`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: payload.projectName
              ? i18n.t('customer.toast.success.create_project_basic', {
                  project: payload.projectName
                })
              : i18n.t('customer.toast.success.create_project_unnamed_basic')
          },
          { root: true }
        )
        await commit('modals/toggleProjectModal', null, { root: true })
        await dispatch(
          'workspace/getProjects',
          {
            currentPage: 1,
            search: null,
            folderId: rootState['workspace'].folderId
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createDocumentProject({ dispatch, rootState, rootGetters }, payload) {
    const data = {
      data: {
        type: 'projects',
        attributes: {
          source_language: payload.source,
          target_languages: payload.targets,
          workflow_id: payload.workflow_id,
          copyedit_source_language: payload.copyedit_source_language,
          content: {
            key: payload.content
          }
        }
      }
    }

    if (payload.instructions) {
      data.data.attributes = {
        ...data.data.attributes,
        customer_instructions: payload.instructions
      }
    }
    if (payload.projectName) {
      data.data.attributes = {
        ...data.data.attributes,
        name: payload.projectName
      }
    }
    if (payload.deadline) {
      data.data.attributes = {
        ...data.data.attributes,
        preferred_deadline: payload.deadline
      }
    }
    if (payload.folderId) {
      data.data.attributes = {
        ...data.data.attributes,
        folder_id: payload.folderId
      }
    }

    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState['workspace'].currentUser.id}/content-generation/documents/${rootState['contentGeneration'].currentDocument.id}/create-project`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: payload.projectName
              ? 'customer.toast.success.create_project'
              : 'customer.toast.success.create_project_unnamed',
            hasDynamicValues: true,
            options: [
              {
                name: 'project',
                value: payload.projectName
              }
            ]
          },
          { root: true }
        )
        await dispatch(
          'workspace/getProjects',
          { currentPage: 1, search: null },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data.message))
      })
  }
}

export const projects = {
  namespaced: true,
  actions
}
