import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  currentMachineTranslation: {},
  trainingModels: [],
  currentTrainingModels: [],
  billingAttributes: {}
}

const mutations = {
  setCurrentMachineTranslation(state, currentMt) {
    state.currentMachineTranslation = { ...currentMt }
  },
  setTrainingModels(state, models) {
    state.trainingModels = [...models]
  },
  setCurrentTrainingModels(state, model) {
    state.currentTrainingModels = [...state.currentTrainingModels, model]
  },
  setBillingAttributes(state, billingAttributes) {
    state.billingAttributes = billingAttributes
  }
}

const actions = {
  async getModels({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/custom-machine-translation/training-models`
    )
      .then((res) => {
        const models = res.data.data
        commit('setTrainingModels', models)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getModel({ commit, rootGetters }, modelId) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/custom-machine-translation/training-models/${modelId}`
    )
      .then((res) => {
        const model = res.data.data
        commit('setCurrentTrainingModels', model)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async estimateCost({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'custom_machine_translations',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/custom-machine-translation/estimate-cost`,
      data
    )
      .then((res) => {
        const machineTranslation = res.data.data.attributes
        commit('setCurrentMachineTranslation', machineTranslation)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async startModel({ dispatch, commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'custom_machine_translations',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/custom-machine-translation/training-models`,
      data
    )
      .then((res) => {
        commit('setBillingAttributes', res.data.data)
        if (!res.data.data) {
          commit('setCurrentMachineTranslation', {})
          dispatch(
            'toast/success',
            {
              message: i18n.t(
                'customer.machine_translation.custom_mt.view.success_toast'
              )
            },
            { root: true }
          )
        }
      })
      .then(async () => {
        await dispatch('getModels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async cancelTraining({ dispatch, rootGetters }, modelId) {
    const data = {
      data: {
        type: 'custom_machine_translations',
        attributes: {
          status: 'cancelled'
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/custom-machine-translation/training-models/${modelId}`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          {
            message: i18n.t(
              'customer.machine_translation.custom_mt.view.cancel_training_success'
            )
          },
          { root: true }
        )
      })
      .then(async () => {
        await dispatch('getModels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const machineTranslation = {
  namespaced: true,
  state,
  actions,
  mutations
}
