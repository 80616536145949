import ApiLibraryService from '@/api/new/services/api.library.service'
import i18n from '@/i18n'
import FileSaver from 'file-saver'

const state = {
  library: {},
  libraryKeys: [],
  file: [],
  modal: {
    showDeleteKey: false,
    showDownloadLibrary: false,
    showEditLibrary: false,
    showStartLibrary: false
  },
  selected: {
    key: null,
    id: null
  },
  selectedKeys: [],
  filter: {
    target: null,
    key: null,
    text: null
  },
  pages: {
    current: 0,
    total: 0
  },
  selectAllFiltered: false
}

const mutations = {
  setLibrary(state, library) {
    state.library = library
  },
  setLibraryKeys(state, libraryKeys) {
    state.libraryKeys = libraryKeys
  },
  setFile(state, file) {
    state.file = file
  },
  toggleDeleteKeyModal(state, bool) {
    state.modal.showDeleteKey = bool
  },
  toggleDownloadModal(state, bool) {
    state.modal.showDownloadLibrary = bool
  },
  toggleStartModal(state, bool) {
    state.modal.showStartLibrary = bool
  },
  toggleSettingsModal(state, bool) {
    state.modal.showEditLibrary = bool
  },
  setSelectedKey(state, { name, id }) {
    state.selected.key = name
    state.selected.id = id
  },
  setSelectedKeys(state, keys) {
    state.selectedKeys = keys
  },
  selectKey(state, key) {
    if (!state.selectedKeys.includes(key)) {
      state.selectedKeys = [...state.selectedKeys, key]
    }
  },
  deselectKey(state, key) {
    const index = state.selectedKeys.indexOf(key)
    if (index > -1) {
      let result = [...state.selectedKeys]
      result.splice(index, 1)
      state.selectedKeys = result
    }
    if (state.selectAllFiltered) {
      state.selectAllFiltered = false
    }
  },
  filterTarget(state, target) {
    state.filter.target = target
  },
  filterKey(state, key) {
    state.filter.key = key
  },
  filterText(state, text) {
    state.filter.text = text
  },
  clearFilter(state) {
    state.filter = {
      target: null,
      key: null,
      text: null
    }
  },
  clearSelected(state) {
    state.selected = {
      key: null,
      id: null
    }
  },
  setPages(state, pages) {
    state.pages.current = pages.current_page
    state.pages.total = pages.last_page
  },
  setSelectAllFiltered(state, bool) {
    state.selectAllFiltered = bool
  }
}

const actions = {
  async getLibrary({ commit, rootGetters }, { id }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}`
    )
      .then((res) => {
        const library = res.data.data
        commit('setLibrary', library)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async editLibrary({ dispatch, state, rootGetters }, { name, description }) {
    const data = {
      data: {
        type: 'library',
        attributes: {
          name,
          description
        }
      }
    }
    await ApiLibraryService.patch(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}`,
      data
    )
      .then(async (res) => {
        await dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.saved_title'),
            message: i18n.t('customer.toast.success.library_saved', {
              library: name
            })
          },
          { root: true }
        )
        await dispatch(
          'libraries/getLibraries',
          { currentPage: 1, perPage: 50, type: { caas: true, string: true } },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getLibraryKeys({ commit, rootGetters }, { id }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}/keys`
    )
      .then((res) => {
        const libraryKeys = res.data.data
        commit('setLibraryKeys', libraryKeys)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getLibraryFile({ state, commit, rootGetters }, { id, currentPage }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}/bilingual`,
      {
        params: {
          'filters[key]': state.filter.key,
          'filters[text]': state.filter.text,
          'filters[target_languages][]': state.filter.target,
          page: currentPage,
          perPage: 100
        }
      }
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        await commit('setFile', file)
        await commit('setPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createCaasKey({ dispatch, state, rootGetters }, { payload }) {
    const data = {
      data: {
        type: 'translation-key',
        attributes: payload
      }
    }

    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/keywords`,
      data
    )
      .then(async (res) => {
        await dispatch('getLibraryFile', { id: state.library.id })
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_create_key', {
              key: payload[0].name
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data.message))
      })
  },
  async createLibraryKey({ dispatch, state, rootGetters }, { payload }) {
    const data = {
      data: {
        type: 'translation-key',
        attributes: payload
      }
    }
    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/keys`,
      data
    )
      .then(async (res) => {
        await dispatch('getLibraryFile', { id: state.library.id })
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_create_key', {
              key: payload[0].name
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteLibraryKeys({ dispatch, state, commit, rootGetters }) {
    let data
    if (state.selectAllFiltered) {
      const { key, text } = state.filter
      data = {
        data: {
          type: 'translation-keys',
          attributes: {
            query: {
              key,
              text
            }
          }
        }
      }
    } else {
      data = {
        data: {
          type: 'translation-keys',
          attributes: {
            key_names: state.selectedKeys
          }
        }
      }
    }
    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/keys/delete`,
      data
    )
      .then(async () => {
        await dispatch('getLibraryFile', { id: state.library.id })
        commit('setSelectedKeys', [])
        await dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.library_delete_key') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateLibraryString({ dispatch, state, rootGetters }, { id, text }) {
    const data = {
      data: {
        type: 'translation',
        attributes: {
          text
        }
      }
    }
    await ApiLibraryService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/translations/${id}`,
      data
    )
      .then(async () => {
        await dispatch('getLibraryFile', {
          id: state.library.id,
          currentPage: state.pages.current
        })
        await dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.library_save_string') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async startLibrary({ dispatch, state, rootGetters }, { target_languages }) {
    let data
    if (state.selectAllFiltered) {
      const { key, text } = state.filter
      data = {
        data: {
          type: 'library_automation',
          attributes: {
            target_languages,
            query: {
              key,
              text
            }
          }
        }
      }
    } else if (state.selectedKeys.length > 0) {
      data = {
        data: {
          type: 'library_automation',
          attributes: {
            target_languages,
            key_names: state.selectedKeys
          }
        }
      }
    } else {
      data = {
        data: {
          type: 'library_automation',
          attributes: {
            target_languages
          }
        }
      }
    }
    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/start-automation`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.library_automation_on') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadLibrary({ state, rootGetters }, { languages, nest }) {
    const data = {
      data: {
        type: 'library-download',
        attributes: {
          languages,
          unpack_strings: nest
        }
      }
    }
    const requestData = {
      method: 'post',
      url: `strings-library/api/v1/teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/download`,
      data,
      responseType: 'blob'
    }

    await ApiLibraryService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/zip' })
        )
        FileSaver.saveAs(fileSource, `${state.library.attributes.name}.zip`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadTranslation({ state, rootGetters, rootState }, { languages }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${state.library.id}/translations/${languages}/download`
    )
      .then(async (res) => {
        const fileSource = window.URL.createObjectURL(
          new Blob([JSON.stringify(res.data)], { type: 'application/json' })
        )
        if (
          rootState.account.account.attributes.team_identifier == 'hhtesting' &&
          rootGetters['account/enableOnTesting']
        ) {
          FileSaver.saveAs(fileSource, `${languages}.json`)
        } else {
          FileSaver.saveAs(fileSource, `${state.library.attributes.name}.json`)
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async selectAll({ state, commit }, isSelected) {
    commit('setSelectAllFiltered', isSelected)
    const keys = state.file.map((file) => {
      return file.attributes.name
    })
    if (isSelected) {
      commit('setSelectedKeys', keys)
    } else {
      commit('setSelectedKeys', [])
    }
  },
  async clearLibrary({ commit }) {
    commit('clearFilter')
    commit('clearSelected')
    commit('setSelectedKeys', [])
  }
}

const getters = {
  targetLanguages: (state) => {
    return state.library.attributes.languages.filter(
      (lang) => lang !== state.library.attributes.source_language
    )
  },
  caasLibrary: (state) => {
    if (Object.keys(state.library).length > 0) {
      return state.library.attributes.type === 'caas'
    }
  }
}

export const library = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
