import ApiService from '@/api/new/services/api.service'
import { $cookies } from '@/main'
import { router } from '@/router/index'

const actions = {
  async registerCustomer({ dispatch, rootState }, requestData) {
    const isRegistered = $cookies.get('isRegistered')
    if (!isRegistered || isRegistered === 'false') {
      const data = {
        data: {
          type: 'customer',
          attributes: {
            ...requestData,
            ...rootState.invitations.user
          }
        }
      }
      await ApiService.publicPost(`oauth/registration/customer`, data)
        .then(async (res) => {
          if (!res.error) {
            if ($cookies.get('env') !== 'sandbox') {
              await dispatch('registrationSuccess')
            } else {
              router.push({ name: 'BaseLogin' })
            }
          } else {
            throw new Error(JSON.stringify(res.error.response.data))
          }
        })
        .catch((err) => {
          throw new Error(JSON.stringify(err))
        })
    } else {
      await dispatch('workspace/getCurrentUser', null, { root: true })
      try {
        const payload = {
          id: rootState.workspace.currentUser.id,
          account: { ...requestData }
        }
        await dispatch('account/updateCustomerProfile', payload, { root: true })
        await dispatch('account/updateCustomerAccountNew', payload, {
          root: true
        })
        await dispatch('account/getCustomerProfile', null, { root: true })
        await dispatch('account/getCustomerAccount', null, { root: true })
      } catch (err) {
        throw new Error(JSON.stringify(err))
      } finally {
        router.push({ name: 'onboardingBilling' })
      }
    }
  },
  async registrationSuccess({ dispatch, rootState, rootGetters }) {
    try {
      const data = {
        username: rootState.invitations.user.email,
        password: rootState.invitations.user.password
      }
      await dispatch('auth/login', data, { root: true })
      await dispatch('workspace/getCurrentUser', null, { root: true })
      if (rootGetters['workspace/isNewUserOfExistingAccount']) {
        dispatch(
          'toast/info',
          {
            message: this.$t('customer.account_info.existing_domain')
          },
          { root: true }
        )
        return
      }
      dispatch('setCustomerCookies')
      router.push({ name: 'onboardingBilling' })
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },
  async registerFreemiumCustomer({ dispatch }, payload) {
    const data = {
      data: {
        type: 'customer',
        attributes: {
          ...payload
        }
      }
    }

    await ApiService.publicPost(`oauth/registration/customer`, data)
      .then(async (res) => {
        if (!res.error) {
          const loginPayload = {
            username: payload.email,
            password: payload.password
          }
          await dispatch('auth/login', loginPayload, { root: true })
        } else {
          throw new Error(JSON.stringify(res.error.response.data))
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  setCustomerCookies({ rootState }) {
    $cookies.set('userType', 'customer')
    $cookies.set(
      'firstName',
      rootState.workspace.currentUser.attributes.first_name
    )
    $cookies.set(
      'lastName',
      rootState.workspace.currentUser.attributes.last_name
    )
    $cookies.set('superAdmin', 'false')
    $cookies.set('isRegistered', 'true')
  }
}

export const registration = {
  namespaced: true,
  actions
}
