import ApiService from '@/api/new/services/api.service'
import FileSaver from 'file-saver'
import moment from 'moment'
import { groupBy } from 'underscore'

const state = {
  channels: {},
  contacts: {},
  currentConversation: {},
  messages: [],
  message: {},
  savedItems: [],
  unreadMessages: [],
  existingProjectConversation: {}
}

const mutations = {
  setChannels(state, channels) {
    state.channels = channels
  },
  setContacts(state, contacts) {
    state.contacts = contacts
  },
  setCurrentConversation(state, conversation) {
    state.currentConversation = conversation
  },
  setMessages(state, messages) {
    state.messages = messages
  },
  addMessage(state, message) {
    state.messages = [...state.messages, message]
  },
  removeMessage(state, message) {
    state.messages = state.messages.filter((m) => m.id !== message.id)
  },
  updateMessage(state, message) {
    state.messages = [
      ...state.messages.filter((m) => m.id !== message.id),
      message
    ]
  },
  setSavedItems(state, items) {
    state.savedItems = items
  },
  setUnreadMessages(state, items) {
    state.unreadMessages = items
  },
  readCurrentConversation(state) {
    const count = state.currentConversation.attributes.unread_messages
    state.currentConversation.attributes.unread_messages = 0
    state.currentAccount.unread_messages -= count
  },
  setExistingProjectConversation(state, projectConversation) {
    state.existingProjectConversation = projectConversation
  }
}

const actions = {
  async getChannels({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels`
    )
      .then(async (res) => {
        const channels = res.data.data.attributes.available_channels
        const contacts = res.data.data.attributes.available_direct_messages
        const savedItems = res.data.data.attributes.saved_items
        const unreadMessages = res.data.data.attributes.unread_messages

        commit('setChannels', channels)
        commit('setContacts', contacts)
        commit('setSavedItems', savedItems)
        commit('setUnreadMessages', unreadMessages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createChannel(
    { dispatch, commit, getters, rootGetters },
    { receivers, display_name, description }
  ) {
    const existingConversation = getters.channels.find(
      (channel) => channel.attributes.display_name === display_name
    )
    if (existingConversation) {
      if (existingConversation.attributes.hidden) {
        await dispatch('recoverConversation', existingConversation.id)
      } else {
        dispatch('setCurrentConversationById', existingConversation.id)
      }
      return
    }
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          name: display_name,
          display_name: display_name,
          description: description,
          type: 'channel'
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels`,
      data
    )
      .then(async (res) => {
        commit('setMessages', [])
        commit('setCurrentConversation', res.data.data)
        await dispatch('addMembers', receivers)
        await dispatch('showAllMessages')
        dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createDirectMessage(
    { dispatch, commit, getters, rootGetters },
    receiver
  ) {
    const existingConversation = getters.directMessages.find((dm) => {
      const members = [
        ...dm.attributes.members.customers,
        ...dm.attributes.members.suppliers
      ]
      if (members.some((member) => member.id === receiver.id)) {
        return true
      } else {
        return false
      }
    })
    if (existingConversation) {
      if (existingConversation.attributes.hidden) {
        await dispatch('recoverConversation', existingConversation.id)
      } else {
        dispatch('setCurrentConversationById', existingConversation.id)
      }
      return
    }
    const members = {
      ...(receiver.type === 'suppliers'
        ? { suppliers: [receiver] }
        : { customers: [receiver] })
    }
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          type: 'direct_message',
          members
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/direct-message`,
      data
    )
      .then(async (res) => {
        dispatch('getChannels')
        await commit('setMessages', [])
        await commit('setCurrentConversation', res.data.data)
        await dispatch('showAllMessages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createGroupMessage(
    { dispatch, commit, getters, rootGetters },
    receivers
  ) {
    const existingConversation = getters.groupMessages.find((message) => {
      const existingMembers = [
        ...message.attributes.members.customers,
        ...message.attributes.members.suppliers
      ].map((member) => member.id)
      const newMembers = [...receivers, getters.currentChatUser].map(
        (member) => member.id
      )
      const isEqual =
        existingMembers.length === newMembers.length &&
        existingMembers.every((member) => newMembers.includes(member))
      if (isEqual) {
        return true
      } else {
        return false
      }
    })
    if (existingConversation) {
      if (existingConversation.attributes.hidden) {
        await dispatch('recoverConversation', existingConversation.id)
      } else {
        dispatch('setCurrentConversationById', existingConversation.id)
      }
      return
    }
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          type: 'group_direct_message',
          members: {
            suppliers: receivers
              .filter((receiver) => receiver.type === 'suppliers')
              .map((receiver) => {
                return { id: receiver.id }
              }),
            customers: receivers
              .filter((receiver) => receiver.type === 'customers')
              .map((receiver) => {
                return { id: receiver.id }
              })
          }
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/group-message`,
      data
    )
      .then(async (res) => {
        dispatch('getChannels')
        commit('setCurrentConversation', res.data.data)
        commit('setMessages', [])
        await dispatch('showAllMessages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createProjectChannel(
    { dispatch, commit, rootGetters },
    { suppliers, customers, description, name }
  ) {
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          name: name,
          display_name: name,
          description: description,
          type: 'project_message',
          members: {
            suppliers: suppliers,
            customers: customers
          }
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/project-message`,
      data
    )
      .then(async (res) => {
        dispatch('getChannels')
        commit('setCurrentConversation', res.data.data)
        commit('setMessages', [])
        await dispatch('showAllMessages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async addMembers({ state, commit, rootState, rootGetters }, contacts) {
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          suppliers: contacts
            .filter((contact) => contact.type === 'suppliers')
            .map((contact) => {
              return { id: contact.id }
            }),
          customers: contacts
            .filter((contact) => contact.type === 'customers')
            .map((contact) => {
              return { id: contact.id }
            })
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/add-members`,
      data
    )
      .then(async (res) => {
        commit('setCurrentConversation', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async sendMessage(
    { state, dispatch, rootGetters },
    { message, files, isProject }
  ) {
    const formData = new FormData()
    files.forEach((file, index) => {
      formData.append(`data[attributes][files][${index}]`, file)
    })
    formData.append('data[type]', 'chat_messages')
    formData.append('data[attributes][body]', message)
    formData.append('data[attributes][status]', 'sent')

    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/messages`,
      formData
    )
      .then(async () => {
        if (isProject) {
          await dispatch('showAllMessages')
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateMessage({ commit, rootGetters }, { channelId, messageId }) {
    const data = {
      data: {
        type: 'chat_messages',
        attributes: {
          body: 'test message',
          status: 'draft'
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${channelId}/messages/${messageId}`,
      data
    )
      .then(async (res) => {
        const message = res.data.data
        commit('updateMessage', message)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadMessageFile(
    { state, rootGetters },
    { messageId, fileId, filename, channelId }
  ) {
    const conversationId = channelId ? channelId : state.currentConversation.id
    const requestData = {
      method: 'get',
      url: `/laas/api/v1/teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${conversationId}/messages/${messageId}/files/${fileId}`,
      data: {},
      responseType: 'blob'
    }

    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(new Blob([res.data]))
        FileSaver.saveAs(fileSource, filename)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async showAllMessages({ state, commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/messages/`
    )
      .then(async (res) => {
        const messages = res.data.data
        const sortMessages = messages.sort(
          (a, b) =>
            moment(a.attributes.created_at).valueOf() -
            moment(b.attributes.created_at).valueOf()
        )
        commit('setMessages', sortMessages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteMessage({ state, rootGetters }, id) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/messages/${id}`
    )
      .then()
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async hideConversation({ dispatch, commit, rootGetters }, id) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${id}/hide`
    )
      .then(async () => {
        if (id === state.currentConversation.id) {
          await commit('setCurrentConversation', {})
        }
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async unhideConversation({ dispatch, rootGetters }, id) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${id}/unhide`
    )
      .then(async () => {
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async recoverConversation({ dispatch, rootGetters, getters }, id) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${id}/unhide`
    )
      .then(async () => {
        await dispatch('getChannels')
        const conversation = getters.allConversations.find(
          (conversation) => conversation.id === id
        )
        if (conversation) {
          dispatch('setCurrentConversationById', conversation.id)
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteConversation({ dispatch, commit, rootGetters }, id) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${id}`
    )
      .then(async () => {
        if (id === state.currentConversation.id) {
          await commit('setCurrentConversation', {})
        }
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async setCurrentConversationById({ dispatch, commit, getters }, id) {
    const conversation = getters.allConversations.find(
      (conversation) => conversation.id === id
    )
    if (conversation) {
      commit('setMessages', [])
      conversation && (await commit('setCurrentConversation', conversation))
      await dispatch('showAllMessages')
      await dispatch('readCurrentConversation', conversation.id)
    }
  },
  async leaveConversation({ state, commit, dispatch, rootGetters }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/leave`
    )
      .then(async () => {
        await commit('setCurrentConversation', {})
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async removeMembers({ state, dispatch, rootGetters, getters }, members) {
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          suppliers: members
            .filter((m) => m.type === 'suppliers')
            .map((m) => {
              return { id: m.id }
            }),
          customers: members
            .filter((m) => m.type === 'customers')
            .map((m) => {
              return { id: m.id }
            })
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/remove-members`,
      data
    )
      .then(async (res) => {
        const conversation = res.data.data
        const members = getters.getMessageReceiversFromMemberList(
          conversation.attributes.members
        )
        if (members.length === 0) {
          await dispatch('deleteConversation', conversation.id)
        } else {
          await dispatch('getChannels')
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getProjectConversation({ dispatch, getters, commit }, projectId) {
    const conversation = getters.projectMessages.find(
      (c) => c.attributes.description === projectId
    )
    if (conversation) {
      await commit('setCurrentConversation', conversation)
      await dispatch('showAllMessages')
    } else {
      await commit('setCurrentConversation', {})
      commit('setMessages', [])
    }
  },
  async readCurrentConversation({ dispatch, commit, rootGetters }, channelId) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${channelId}/read`
    )
      .then(() => {
        dispatch('getChannels')
        commit('readCurrentConversation')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async addMessage({ commit }, message) {
    commit('addMessage', message)
  },
  async increaseUnread({ state, commit }, channelId) {
    const channel = Object.values(state.channels.channel).find(
      (channel) => channel.id === channelId
    )
    const dm = Object.values(state.channels.direct_message).find(
      (dm) => dm.id === channelId
    )
    const groupDm = Object.values(state.channels.group_direct_message).find(
      (groupDm) => groupDm.id === channelId
    )
    const projectMessages = Object.values(state.channels.project_message).find(
      (project) => project.id === channelId
    )

    if (channel || dm || groupDm || projectMessages) {
      const channels = {
        ...state.channels,
        channel: {
          ...state.channels.channel,
          ...(channel
            ? {
                [channelId]: {
                  ...channel,
                  attributes: {
                    ...channel.attributes,
                    unread_messages: channel.attributes.unread_messages + 1
                  }
                }
              }
            : {})
        },
        direct_message: {
          ...state.channels.direct_message,
          ...(dm
            ? {
                [channelId]: {
                  ...dm,
                  attributes: {
                    ...dm.attributes,
                    unread_messages: dm.attributes.unread_messages + 1
                  }
                }
              }
            : {})
        },
        group_direct_message: {
          ...state.channels.group_direct_message,
          ...(groupDm
            ? {
                [channelId]: {
                  ...groupDm,
                  attributes: {
                    ...groupDm.attributes,
                    unread_messages: groupDm.attributes.unread_messages + 1
                  }
                }
              }
            : {})
        },
        project_message: {
          ...state.channels.project_message,
          ...(projectMessages
            ? {
                [channelId]: {
                  ...projectMessages,
                  attributes: {
                    ...projectMessages.attributes,
                    unread_messages:
                      projectMessages.attributes.unread_messages + 1
                  }
                }
              }
            : {})
        }
      }
      commit('setChannels', channels)
    }
  },
  async saveMessage({ state, dispatch, rootGetters }, messageId) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${state.currentConversation.id}/messages/${messageId}/save`
    )
      .then(async () => {
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async unsaveMessage({ dispatch, rootGetters }, message) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${message.attributes.channel_id}/messages/${message.id}/save`
    )
      .then(async () => {
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getProjectConversationInfoWithProjectName(
    { commit, rootGetters },
    projectName
  ) {
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          name: projectName
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/get-project-message`,
      data
    )
      .then(async (res) => {
        await commit('setExistingProjectConversation', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async joinChannel({ commit, dispatch, rootGetters }, channelId) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/chat/channels/${channelId}/join`
    )
      .then(async () => {
        await dispatch('getChannels')
        await dispatch('setCurrentConversationById', channelId)
        await dispatch('showMessages')
        commit('setExistingProjectConversation', null)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  channels(state) {
    if (!state.channels.channel) {
      return []
    }
    // state.channels expects an object from BE. If state.channels.isArray means channels object is empty, and we need to handle that special case
    return Array.isArray(state.channels)
      ? []
      : Object.values(state.channels.channel)
  },
  projectMessages(state) {
    if (!state.channels.project_message) {
      return []
    }
    return Array.isArray(state.channels)
      ? []
      : Object.values(state.channels.project_message)
  },
  conversationMembers: () => (conversation) => {
    return [
      ...conversation.attributes.members.customers,
      ...conversation.attributes.members.suppliers
    ]
  },
  directMessages(state, getters) {
    if (!state.channels.direct_message) {
      return []
    }
    return Array.isArray(state.channels)
      ? []
      : Object.values(state.channels.direct_message).filter(
          (directMessage) =>
            getters.conversationMembers(directMessage).length > 1
        )
  },
  groupMessages(state) {
    if (!state.channels.group_direct_message) {
      return []
    }
    return Array.isArray(state.channels)
      ? []
      : Object.values(state.channels.group_direct_message)
  },
  contacts(state) {
    if (Object.keys(state.contacts).length > 0) {
      return [
        ...Object.values(state.contacts.customers),
        ...Object.values(state.contacts.suppliers)
      ]
    }
    return []
  },
  customers(state) {
    return [...Object.values(state.contacts.customers)]
  },
  allConversations(state, getters) {
    return [
      ...getters.channels,
      ...getters.directMessages,
      ...getters.groupMessages,
      ...getters.projectMessages
    ]
  },
  allChannels(state, getters) {
    return [...getters.channels, ...getters.projectMessages]
  },
  getMessageReceiversFromMemberList:
    (state, getters, rootState) => (messageMembers) => {
      if (!messageMembers) {
        return []
      }
      const customers = messageMembers.customers.map((c) => {
        return {
          ...c,
          type: 'customers'
        }
      })
      const suppliers = messageMembers.suppliers.map((c) => {
        return {
          ...c,
          type: 'suppliers'
        }
      })
      const members = [...customers, ...suppliers]
      return members.filter(
        (member) => member.id !== rootState['workspace'].currentUser.id
      )
    },
  currentConversationOwner: (state) => {
    const members = [
      ...state.currentConversation.attributes.members.customers,
      ...state.currentConversation.attributes.members.suppliers
    ]
    return members.find(
      (member) =>
        member.attributes['settings'] && member.attributes['settings'].is_owner
    )
  },
  currentChatUser(state, getters, rootState) {
    return getters.contacts.find(
      (contact) => contact.id === rootState['workspace'].currentUser.id
    )
  },
  groupMessagesByDate(state) {
    const date = (date) =>
      moment(date.attributes.created_at).utc().format('dddd, MMMM Do YYYY')
    return groupBy(state.messages, date)
  },
  groupUnreadMessagesById(state) {
    const unreadMessages = state.unreadMessages
      .filter((item) => item.attributes.type !== 'system')
      .reverse()
    return groupBy(unreadMessages, (item) => item.attributes.channel_id)
  },
  savedItems(state) {
    return state.savedItems
  },
  getConversationWithId: (state, getters) => (id) => {
    const conversation = getters.allConversations.find(
      (conversation) => conversation.id === id
    )
    return conversation
  },
  getConversationNameWithId: (state, getters) => (id) => {
    const conversation = getters.getConversationWithId(id)
    if (conversation) {
      const receivers = getters
        .getMessageReceiversFromMemberList(conversation.attributes.members)
        .map(
          (member) =>
            `${member.attributes.first_name} ${member.attributes.last_name}`
        )
        .join(', ')
      return conversation.attributes.type === 'channel'
        ? conversation.attributes.display_name
        : receivers
    } else {
      return ''
    }
  },
  isMessageSaved: (state) => (messageId) => {
    return !!state.savedItems.find((item) => item.id === messageId)
  },
  totalUnread(state, getters) {
    if (getters.allConversations.length === 0) {
      return 0
    }
    let total = getters.allConversations.reduce(
      (a, b) => (a += b.attributes.unread_messages),
      0
    )
    return total
  },
  visibleChannels(state, getters) {
    return getters.channels.filter(
      (channel) =>
        !channel.attributes.hidden || channel.attributes.unread_messages > 0
    )
  },
  visibleDirectMessages(state, getters) {
    return getters.directMessages.filter(
      (dm) => !dm.attributes.hidden || dm.attributes.unread_messages > 0
    )
  },
  visibleGroupMessages(state, getters) {
    return getters.groupMessages.filter(
      (groupDms) =>
        !groupDms.attributes.hidden || groupDms.attributes.unread_messages > 0
    )
  },
  visibleProjectMessages(state, getters) {
    return getters.projectMessages.filter(
      (projectMessage) =>
        !projectMessage.attributes.hidden ||
        projectMessage.attributes.unread_messages > 0
    )
  }
}

export const chat = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
