import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  termsContent: [],
  generalTerms: {},
  marketingTerms: {},
  supplierTerms: {}
}

const mutations = {
  setAllTerms(state, response) {
    state.termsContent = response
    response.forEach((element) => {
      switch (element.attributes.type) {
        case 'marketing':
          state.marketingTerms = element
          break
        case 'terms':
          state.generalTerms = element
          break
        case 'supplier_terms':
          state.supplierTerms = element
          break
      }
    })
  }
}

const actions = {
  async getAllTerms({ commit }) {
    const reqParams = {
      locale: 'en',
      terms: ['marketing', 'terms', 'supplier_terms']
    }
    const params = new URLSearchParams()
    params.append('type[0]', reqParams.terms[0])
    params.append('type[1]', reqParams.terms[1])
    params.append('type[2]', reqParams.terms[2])
    params.append('locale', reqParams.locale)

    await ApiService.publicGet(`user-consents/file`, { params })
      .then((res) => {
        commit('setAllTerms', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAllTerms({ dispatch }, requestData) {
    const data = {
      data: {
        type: 'user-consents',
        attributes: {
          consents: {
            terms: requestData.terms,
            ai: requestData.ai,
            marketing: requestData.marketing
          }
        }
      }
    }
    await ApiService.put(`user-consents`, data)
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.privacy_consent_updated') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const newUserTerms = {
  namespaced: true,
  state,
  actions,
  mutations
}
