import ApiService from '@/api/new/services/api.service'
import ApiServiceV2 from '@/api/new/services/v2/api.service'
import axios from 'axios'
import FileSaver from 'file-saver'

const state = {
  currentProject: {},
  currentProjectTasks: []
}

const mutations = {
  setCurrentProject(state, project) {
    state.currentProject = project
    state.currentProjectTasks = project.attributes.tasks
  },
  clearCurrentProject(state) {
    state.currentProject = {}
    state.projectTasks = []
  }
}

const actions = {
  async getStateProject({ dispatch, state }, id) {
    if (
      Object.keys(state.currentProject).length === 0 ||
      id != state.currentProject.id
    )
      await dispatch('getProject', id)
  },
  async getProject({ commit, dispatch, getters, rootGetters }, projectId) {
    await ApiServiceV2.get(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}`
    )
      .then(async (res) => {
        const currentProject = res.data.data
        await commit('setCurrentProject', currentProject)
        if (getters.aiType && rootGetters['aiFile/fileId'])
          await dispatch('aiFile/getUploadedFile', {}, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getPrepareTargetZip({ dispatch, rootGetters }, projectId) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/zip/prepare`
    )
      .then(async () => {
        await dispatch('getProject', projectId)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadTargetZip({ rootGetters }, { projectId, fileName }) {
    const requestData = {
      method: 'post',
      url: `/laas/api/v1/teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/zip/download`,
      data: {},
      responseType: 'blob'
    }

    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/zip' })
        )
        FileSaver.saveAs(fileSource, `${fileName}.zip`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadTaskReport({ rootGetters }, { from, to }) {
    const data = {
      type: 'account',

      attributes: {
        from,
        to
      }
    }

    await axios
      .post(
        `/laas/api/v1/teams/${rootGetters['workspace/currentAccountId']}/task-report/csv/download`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json'
          },
          data
        }
      )
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/csv' })
        )
        FileSaver.saveAs(fileSource, `${from}-${to}.csv`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  aiType(state) {
    if (!state.currentProject.attributes) {
      return false
    }
    return state.currentProject.attributes.tasks.some(
      (task) => task.attributes.type === 'ai_translation'
    )
  },
  caasType(state) {
    if (!state.currentProject.attributes) {
      return false
    }
    return state.currentProject.attributes.tasks.some(
      (task) => task.attributes.type === 'caas'
    )
  },
  caasTask(state) {
    if (!state.currentProject.attributes) {
      return false
    }
    return state.currentProject.attributes.tasks.find(
      (task) => task.attributes.type === 'caas'
    )
  },
  translateYourself(state) {
    return (
      state.currentProject.attributes.workflow === 'self+machine_translation'
    )
  },
  projectTasks(state) {
    if (!state.currentProject.attributes) return []
    let array = []
    state.currentProject.attributes.target_languages.forEach((lang, i) => {
      array[i] = { target: lang, tasks: [] }
      state.currentProjectTasks.forEach((task) => {
        if (lang === task.attributes.target_language) array[i].tasks.push(task)
      })
    })
    array.forEach((project) => {
      project.tasks.sort((a, b) => a.attributes.order - b.attributes.order)
    })
    return array
  },
  getProjectTask: (state, getters) => (lang) => {
    return getters.projectTasks.find((task) => {
      if (task.target === lang) return task
    })
  }
}

export const project = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
