import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import { router } from '@/router/index'

const actions = {
  async sendForgotPasswordEmail({ dispatch }, requestData) {
    const data = {
      data: {
        type: 'forgot-password',
        attributes: {
          email: requestData
        }
      }
    }

    await ApiService.publicPost('oauth/password/email', data)
      .then(() => {
        dispatch(
          'toast/success',
          {
            message: i18n.t('shared.toast.success.send_forgot_password_email')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async changeUserPassword({ dispatch }, requestData) {
    const data = {
      data: {
        type: 'change-password',
        attributes: {
          current_password: requestData.currentPassword,
          password: requestData.password,
          password_confirmation: requestData.confirmPassword
        }
      }
    }
    await ApiService.post('oauth/password/update', data)
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.password_change') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async resetPassword({ dispatch }, requestData) {
    const data = {
      data: {
        type: 'change-password',
        attributes: requestData
      }
    }
    ApiService.publicPost('oauth/password/reset', data)
      .then(() => {
        dispatch(
          'toast/success',
          {
            message: i18n.t('shared.toast.success.password_change')
          },
          { root: true }
        )
        router.push({ name: 'BaseLogin' })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const passwordReset = {
  namespaced: true,
  actions
}
