import ApiService from '@/api/new/services/api.service'
import { TokenService } from '@/api/new/services/storage.service'
import { refreshAccessToken } from '@/api/new/services/tokenRefresh.service'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { router } from '@/router/index'
import store from '@/store/store'
import { fetchEventSource } from '@microsoft/fetch-event-source'

class FatalError extends Error {}
class RetriableError extends Error {}

const state = {
  content: null,
  currentTemplate: null,
  documents: [],
  rawContent: null,
  status: null,
  templates: [],
  currentDocument: null,
  templatesPages: {
    perPage: 15,
    current: 0,
    total: 2
  },
  documentsPages: {
    perPage: 15,
    current: 0,
    total: 2
  },
  promptId: null,
  appliedTemplate: {}
}

const mutations = {
  applyTemplate(state, template) {
    state.appliedTemplate = template
  },
  setGeneratedContent(state, content) {
    state.content = content
  },
  setCurrentTemplate(state, template) {
    state.currentTemplate = template
  },
  setDocuments(state, documents) {
    state.documents = documents
  },
  setRawContent(state, rawContent) {
    state.rawContent = rawContent
  },
  setStatus(state, status) {
    state.status = status
  },
  setTemplates(state, templates) {
    state.templates = templates
  },
  setTemplatesPages(state, pages) {
    state.templatesPages.current = pages.current_page
    state.templatesPages.total = pages.last_page
    state.templatesPages.perPage = pages.per_page
  },
  setDocumentsPages(state, pages) {
    state.documentsPages.current = pages.current_page
    state.documentsPages.total = pages.last_page
    state.documentsPages.perPage = pages.per_page
  },
  setCurrentDocument(state, document) {
    state.currentDocument = document
  },
  setPromptId(state, id) {
    state.promptId = id
  }
}

const actions = {
  async generateContent({ commit, rootGetters, rootState }, payload) {
    let content = ''
    let env = process.env.VUE_APP_ROOT_API
    const isSupplier = $cookies.get('userType') === 'supplier'
    const path = isSupplier
      ? `suppliers/${rootGetters['supplier/supplierId']}`
      : `teams/${rootGetters['workspace/currentAccountId']}`
    if ($cookies.get('env') == 'sandbox') {
      env = process.env.VUE_APP_ROOT_API_SANDBOX
    }
    await fetchEventSource(`${env}laas/api/v1/${path}/caas/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      openWhenHidden: true,
      body: JSON.stringify({
        data: {
          type: 'content_generation',
          attributes: {
            ...payload
          }
        }
      }),
      async onopen(response) {
        const refreshToken = TokenService.getRefreshToken()
        const isTokenRefreshed = rootState['auth'].tokenRefreshed
        if (response.status === 401) {
          if (refreshToken && isTokenRefreshed) {
            await refreshAccessToken(null, refreshToken)
            if (rootState['auth'].tokenRefreshed) throw new RetriableError()
            else throw new FatalError()
          } else {
            TokenService.removeAll()
            router.push({ name: 'BaseLogin' })
            store.dispatch(
              'toast/error',
              {
                title: i18n.t('shared.toast.error.title'),
                message: i18n.t('customer.toast.error.something_went_wrong')
              },
              { root: true }
            )
          }
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429 &&
          response.status !== 401
        ) {
          throw new FatalError()
        } else if (response.status == 429) {
          store.dispatch('toast/error', {
            message: i18n.t('customer.toast.error.something_went_wrong')
          })
        }
      },
      onmessage(msg) {
        if (msg.data === '[DONE]') {
          return
        }
        content += JSON.parse(
          msg.data
        ).data.attributes.generated_content.replaceAll('\\n', '\n')
        commit('setGeneratedContent', content)
        const prompt_id = JSON.parse(msg.data).data.attributes.prompt_id
        if (prompt_id) {
          commit('setPromptId', prompt_id)
        }
        if (msg.event === 'FatalError') {
          throw new FatalError(msg.data)
        }
      },
      onclose() {
        commit('setGeneratedContent', 'done')
        return
      },
      onerror(err) {
        if (err instanceof FatalError) {
          if (!isSupplier) {
            store.dispatch('payment/getStateBilling')
            commit('modals/toggleUpgradeSubscriptionModal', {}, { root: true })
          }
          throw err
        }
      }
    })
  },
  async createDocument({ rootGetters, rootState, dispatch, commit }, payload) {
    const data = {
      data: {
        type: 'content_generation_document',
        attributes: {
          template_id: payload.template_id,
          title: payload.title,
          content: payload.content,
          raw_content: payload.raw_content,
          prompt_id: payload.prompt_id,
          meta: payload.meta || []
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/documents`,
      data
    )
      .then((res) => {
        commit('setCurrentDocument', res.data.data)
        dispatch(
          'toast/success',
          {
            message: i18n.t(
              'customer.content_generation.document.create.success'
            )
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateDocuments({ dispatch, state }, payload) {
    if (state.documents.length === 0 || state.documentsPages.current != 1)
      await dispatch('getDocuments', payload)
  },
  async getDocuments(
    { commit, rootGetters, rootState },
    { currentPage, perPage }
  ) {
    const params = new URLSearchParams()
    params.append('page', currentPage)
    if (perPage) {
      params.append('perPage', perPage)
    }
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/documents`,
      {
        params
      }
    )
      .then(async (res) => {
        commit('setDocuments', res.data.data)
        commit('setDocumentsPages', res.data.meta)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteDocument({ commit, rootGetters, rootState }, documentId) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/documents/${documentId}`
    )
      .then(() => {
        store.dispatch('toast/success', {
          message: i18n.t('customer.content_generation.document.delete.success')
        })
        commit('setCurrentDocument', null)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createTemplate({ commit, rootGetters, rootState }, payload) {
    const data = {
      data: {
        type: 'content_generation_template',
        attributes: { ...payload.attributes }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/templates`,
      data
    )
      .then((res) => {
        commit('setCurrentTemplate', res.data.data)
        store.dispatch('contentGeneration/getTemplates', {
          currentPage: 1
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getDocument({ commit, rootGetters, rootState }, documentId) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/documents/${documentId}`
    )
      .then((res) => {
        commit('setCurrentDocument', res.data.data)
        commit('setCurrentTemplate', res.data.data.attributes.meta[0])
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateDocument({ commit, dispatch, rootGetters, rootState }, payload) {
    const data = {
      data: {
        type: 'content_generation_document',
        attributes: { ...payload.attributes }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/documents/${payload.id}`,
      data
    )
      .then((res) => {
        if (payload.isHeader) {
          commit('setCurrentDocument', res.data.data)
        } else {
          store.dispatch('toast/success', {
            message: i18n.t('customer.content_generation.document.name.success')
          })
        }
        dispatch('getDocuments', { currentPage: 1 })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateTemplates({ dispatch, state }, payload) {
    if (state.templates.length === 0 || state.templatesPages.current != 1)
      await dispatch('getTemplates', payload)
  },
  async getTemplates(
    { commit, rootGetters, rootState },
    { currentPage, perPage }
  ) {
    const params = new URLSearchParams()
    params.append('page', currentPage || 1)
    if (perPage) {
      params.append('perPage', perPage)
    }
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/templates`,
      {
        params
      }
    )
      .then(async (res) => {
        const templates = res.data.data
        const pages = res.data.meta
        commit('setTemplates', templates)
        commit('setTemplatesPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTemplate({ commit, rootGetters, rootState }, templateId) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/templates/${templateId}`
    )
      .then(() => {
        store.dispatch('toast/success', {
          message: i18n.t('customer.content_generation.template.delete.success')
        })
        commit('setCurrentTemplate', null)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTemplate({ commit, rootGetters, rootState }, templateId) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/templates/${templateId}`
    )
      .then((res) => {
        commit('setCurrentTemplate', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTemplate({ commit, rootGetters, rootState }, payload) {
    const data = {
      data: {
        type: 'content_generation_template',
        attributes: { ...payload.attributes }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${rootState.workspace.currentUser.id}/content-generation/templates/${payload.id}`,
      data
    )
      .then((res) => {
        commit('setCurrentTemplate', res.data.data)
        store.dispatch('contentGeneration/getTemplates', {
          currentPage: 1
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const contentGeneration = {
  namespaced: true,
  state,
  actions,
  mutations
}
