import ApiService from '@/api/new/services/api.service'
import _ from 'underscore'

const defaultState = () => {
  return {
    currentWorkflow: {},
    sidebar: {
      show: false,
      view: {
        actions: true,
        action: false,
        editAction: false,
        settings: false
      }
    },
    step: {
      identifier: null,
      child_identifier: null,
      translation_team: []
    },
    action: {
      identifier: null
    },
    zoom: 1
  }
}

const state = defaultState()

const mutations = {
  setCurrentWorkflow(state, workflow) {
    state.currentWorkflow = workflow
  },
  setCurrentView(state, view) {
    _.mapObject(state.sidebar.view, (val, key) => {
      if (key === view) {
        state.sidebar.view[key] = true
      } else {
        state.sidebar.view[key] = false
      }
    })
  },
  toggleSidebar(state, show) {
    state.sidebar.show = show
  },
  clickedStep(state, { identifier, child_identifier }) {
    state.step.identifier = identifier
    state.step.child_identifier = child_identifier
    if (identifier !== 'new') {
      state.action.identifier = identifier
    }
  },
  clickedAction(state, identifier) {
    state.action.identifier = identifier
    if (state.step.identifier !== 'new') {
      state.step.identifier = null
    }
  },
  setZoom(state, zoom) {
    state.zoom = zoom
  },
  setTranslationTeam(state, team) {
    state.step.translation_team = team
  },
  resetState(state) {
    Object.assign(state.step, defaultState().step)
    Object.assign(state.sidebar, defaultState().sidebar)
    Object.assign(state.action, defaultState().action)
  }
}

const actions = {
  async getWorkflow({ commit, rootGetters }, { id }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/workflows/${id}`
    )
      .then(async (res) => {
        const workflow = res.data.data
        await commit('setCurrentWorkflow', workflow)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateWorkflow(
    { dispatch, commit, state, rootGetters },
    { display_name, description, status, steps, getWorkflow }
  ) {
    const data = {
      data: {
        type: 'account_workflows',
        attributes: {
          display_name,
          status,
          steps
        }
      }
    }
    if (description) {
      data.data.attributes.description = description
    }

    await ApiService.patch(
      `teams/${rootGetters['workspace/currentAccountId']}/workflows/${state.currentWorkflow.id}`,
      data
    )
      .then(async () => {
        await dispatch('getWorkflow', { id: state.currentWorkflow.id })
        if (getWorkflow)
          await dispatch('automationFlows/getWorkflows', null, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async translationTeam({ commit, rootGetters, state }, { target }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-team?filters[source_language][]=${state.currentWorkflow.attributes.source_language.code}&filters[target_language][]=${target}`,
      {
        params: {
          page: 1,
          perPage: 100
        }
      }
    )
      .then(async (res) => {
        const translationTeam = res.data.data
        await commit('setTranslationTeam', translationTeam)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  targetLanguages: (state, getters, rootState) => {
    const targetStep = state.currentWorkflow.attributes.available_steps.find(
      (step) => step.attributes.identifier === 'supplier_translation'
    )
    return targetStep.attributes.target_languages
  },
  selectedAction: (state, getters, rootState) => {
    if (Object.keys(state.currentWorkflow).length > 0) {
      return state.currentWorkflow.attributes.available_steps.find(
        (step) => step.attributes.identifier === state.action.identifier
      )
    }
  },
  selectedStep: (state, getters, rootState) => {
    if (Object.keys(state.currentWorkflow).length > 0) {
      return state.currentWorkflow.attributes.available_steps.find(
        (step) => step.attributes.identifier === state.step.identifier
      )
    }
  },
  selectedChildStep: (state, getters, rootState) => {
    let childStep
    state.currentWorkflow.attributes.available_steps.forEach((step) => {
      if (step.attributes.identifier === state.step.identifier) {
        return (childStep = step.attributes.steps.find(
          (childStep) =>
            childStep.attributes.identifier === state.step.child_identifier
        ))
      }
    })
    return childStep
  },
  validStep: (state, getters) => (s) => {
    const step = state.currentWorkflow.attributes.steps.find(
      (oneStep) => oneStep.attributes.identifier === s
    )
    return step.attributes.steps.every((s) =>
      getters.validChildStep(
        s.attributes,
        step.attributes.meta.laas.target_languages.find(
          (t) => t.target_language.code === s.attributes.target_language.code
        )
      )
    )
  },
  validChildStep: (state) => (step, meta) => {
    if (
      (step.parent_identifier === 'supplier_translation' ||
        step.parent_identifier === 'supplier_review') &&
      step.meta.suppliers.length === 0
    )
      return false
    if (
      step.parent_identifier === 'qa' &&
      step.meta['qa_performers'] == undefined
    )
      return false
    else if (
      step.parent_identifier === 'qa' &&
      step.meta['qa_performers'] !== undefined
    ) {
      if (
        step.meta.qa_performers.length === 0 ||
        step.meta.qa_subjects.length === 0
      )
        return false
    } else if (step.parent_identifier === 'ai_translation') {
      if (!meta.is_supplier_needed && meta.customers.length === 0) return false
      if (meta.is_supplier_needed && meta.suppliers.length === 0) return false
    }

    return true
  },
  selectedStepExists: (state, getters, rootState) => (identifier) => {
    return state.currentWorkflow.attributes.steps.some(
      (step) => step.attributes.identifier === identifier
    )
  },
  showSidebarAction: (state) => {
    return (
      state.sidebar['view']['actions'] ||
      state.sidebar['view']['action'] ||
      state.sidebar['view']['editAction']
    )
  },
  hideConnector: (state, getters, rootState) => {
    return state.currentWorkflow.attributes.allowed_next_steps.length === 0
  },
  workflowIsActive: (state) => {
    return state.status === 'active'
  },
  workflowTitleStatus: () => (state) => {
    if (state.status === 'active' && state.is_available) return 'On'
    return 'Off'
  },
  supplierServicetype: (state) => (type) => {
    let team = []
    state.step.translation_team.forEach((translator) => {
      translator.attributes.services.forEach((service) => {
        if (service.attributes.type === type && !team.includes(translator)) {
          team.push(translator)
        }
      })
    })
    return team
  },
  initalStep(state) {
    if (Object.keys(state.currentWorkflow).length > 0) {
      return state.currentWorkflow.attributes.steps.length === 0
    }
  },
  workflowTitle: (state) => {
    if (Object.keys(state.currentWorkflow).length > 0) {
      return state.currentWorkflow.attributes.display_name
    }
  }
}

export const automationFlow = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}
