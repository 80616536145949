import ApiService from '@/api/new/services/api.service'
import { TokenService } from '@/api/new/services/storage.service'
import { $cookies } from '@/main'

const state = {
  tokenRefreshed: true
}

const mutations = {
  setTokenRefreshed(state, isRefreshed) {
    state.tokenRefreshed = isRefreshed
  }
}

const actions = {
  async createPersonalAccessToken(_, name) {
    const data = {
      data: {
        type: 'personal_access_tokens',
        attributes: {
          name,
          expires_in: {
            key: 'month',
            value: 12
          }
        }
      }
    }
    await ApiService.personalAccessTokens(data).then(async (res) => {
      await TokenService.saveFigmaPat(res.data.data.attributes.access_token)
      await TokenService.saveHygraphPat(res.data.data.attributes.access_token)
    })
  },
  async refreshToken({ commit }, payload) {
    const data = {
      client_id: process.env.VUE_APP_CLIENT_ID,
      grant_type: 'refresh_token',
      refresh_token: payload.refreshToken,
      scope: ''
    }
    await ApiService.publicLogin(data)
      .then(async (res) => {
        await TokenService.saveRefreshToken(res.data.refresh_token)
        commit('setTokenRefreshed', true)
      })
      .catch((err) => {
        commit('setTokenRefreshed', false)
        if (err.response) {
          throw new Error(JSON.stringify(err))
        }
      })
  },
  async login({ dispatch }, payload) {
    const data = {
      client_id: process.env.VUE_APP_CLIENT_ID,
      grant_type: 'password',
      username: payload.username,
      password: payload.password,
      scope: 'dashboard'
    }
    await ApiService.publicLogin(data)
      .then(async (res) => {
        await TokenService.saveRefreshToken(res.data.refresh_token)
        commit('setTokenRefreshed', true)
      })
      .catch((err) => {
        dispatch('loading/stopAllLoaders', null, { root: true })
        if (err.response) {
          throw new Error(JSON.stringify(err))
        }
      })
  },
  async logoutUser() {
    await ApiService.logout().catch((err) => {
      if (err.response) {
        throw new Error(JSON.stringify(err))
      }
    })
  },
  async signUpWithGoogle({ dispatch }, { token, crm }) {
    const data = {
      data: {
        type: 'registration',
        attributes: {
          provider: 'google',
          id_token: token,
          crm: crm
        }
      }
    }
    await ApiService.publicPost(`oauth/social/callback`, data).catch((err) => {
      if (err.response) {
        throw new Error(JSON.stringify(err))
      }
    })
  },
  async authenticateWithGoogle({ dispatch }, { token, crm }) {
    const data = {
      data: {
        type: 'registration',
        attributes: {
          provider: 'google',
          id_token: token,
          crm: crm
        }
      }
    }
    await ApiService.publicPost(`oauth/social/callback`, data)
      .then((res) => {
        TokenService.saveRefreshToken(res.data.refresh_token)
      })
      .then(async () => {
        await dispatch('workspace/getCurrentUser', {}, { root: true })
      })
      .catch((err) => {
        if (err.response) {
          throw new Error(JSON.stringify(err))
        }
      })
  }
}

const getters = {
  isAuthenticated: () => {
    return $cookies.get(process.env.VUE_APP_REFRESH_TOKEN_COOKIE) !== null
  }
}

export const auth = {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}
