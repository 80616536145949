import ApiConnectorsService from '@/api/new/services/api.connectors.service'
import ApiService from '@/api/new/services/api.service'

const state = {
  steps: [
    {
      step: 1,
      title: 'customer.phrase_connector.step_one.title',
      complete: false,
      routeName: 'clientPhraseConnectorStepOne'
    },
    {
      step: 2,
      title: 'customer.phrase_connector.language_pairing.title',
      complete: false,
      routeName: 'clientLanguagePairing'
    },
    {
      step: 3,
      title: 'customer.phrase_connector.step_two.title',
      complete: false,
      routeName: 'clientPhraseConnectorStepTwo'
    },
    {
      step: 4,
      title: 'customer.phrase_connector.step_three.title',
      routeName: 'clientPhraseConnectorStepThree',
      complete: false,
      webhook: false,
      provider: false
    }
  ],
  mapping: {
    connector: 'phrase',
    source: {},
    target: {}
  },
  statusFetched: false
}

const mutations = {
  setAccess(state, payload) {
    state.steps[0].complete = payload
  },
  setMapping(state, payload) {
    state.steps[1].complete = payload
  },
  setWorkflow(state, payload) {
    state.steps[2].complete = payload
  },
  setWebhook(state, payload) {
    state.steps[3].webhook = payload
  },
  setProvider(state, payload) {
    state.steps[3].provider = payload
  },
  setMappingSource(state, payload) {
    state.mapping.source = payload
  },
  setMappingTarget(state, payload) {
    state.mapping.target = payload
  },
  setMappingSourceValue(state, payload) {
    state.mapping.source[payload.sourceInternal.val] =
      payload.sourceExternal.val
  },
  setMappingTargetValue(state, payload) {
    state.mapping.target[payload.targetInternal.val] =
      payload.targetExternal.val
  },
  deleteSourceMappingValues(state, payload) {
    delete state.mapping.source[payload.sourceInternal.val]
  },
  deleteTargetMappingValues(state, payload) {
    delete state.mapping.target[payload.targetInternal.val]
  },
  clearMapping(state, payload) {
    if (payload == 'source') {
      state.mapping.source = {}
    } else {
      state.mapping.target = {}
    }
  },
  setStatusFetched(state, payload) {
    state.statusFetched = payload
  }
}

const actions = {
  async registerPhrase({ rootState }, payload) {
    try {
      const client_id = localStorage.getItem('phrase_connector_client_id')
      const data = {
        data: {
          type: 'access',
          attributes: {
            team: rootState['account'].account.attributes.team_identifier,
            client_id: client_id,
            code: payload.code,
            state: payload.state
          }
        }
      }

      // phrase register
      const response = await ApiConnectorsService.post(
        `authentication/phrase/register?code=${payload.code}&state=${payload.state}`,
        data
      )

      return response
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },

  async createWebhook({ rootState }) {
    try {
      const data = {
        data: {
          type: 'webhook',
          attributes: {
            team: rootState['account'].account.attributes.team_identifier
          }
        }
      }

      // create webhook
      const response = await ApiConnectorsService.post(
        'authentication/phrase/create_webhook',
        data
      )
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },

  async createProvider({ rootState }) {
    try {
      const data = {
        data: {
          type: 'provider',
          attributes: {
            team: rootState['account'].account.attributes.team_identifier
          }
        }
      }

      // create provider
      const response = await ApiConnectorsService.post(
        'authentication/phrase/create_provider',
        data
      )
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },

  async status({ commit, rootState }) {
    try {
      const data = {
        data: {
          type: 'status',
          attributes: {
            team: rootState['account'].account.attributes.team_identifier
          }
        }
      }

      const response = await ApiConnectorsService.post(
        'authentication/phrase/status',
        data
      )

      commit('setAccess', response.data.data.attributes.access_token)
      commit('setWorkflow', response.data.data.attributes.workflow)
      commit('setWebhook', response.data.data.attributes.webhook)
      commit('setProvider', response.data.data.attributes.provider)
      commit('setStatusFetched', true)
    } catch (err) {
      commit('setStatusFetched', true)
      throw new Error(JSON.stringify(err))
    }
  },

  async fetchLanguageMapping({ commit, rootState }) {
    try {
      const response = await ApiService.get(
        `teams/${rootState['account'].account.attributes.team_identifier}/custom-language-maps/phrase`
      )

      return response
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },

  async createLanguageMapping({ commit, rootState }, payload) {
    try {
      const response = await ApiService.post(
        `teams/${rootState['account'].account.attributes.team_identifier}/custom-language-maps`,
        payload
      )

      return response
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },

  async updateLanguageMapping({ commit, rootState }, payload) {
    try {
      const response = await ApiService.put(
        `teams/${rootState['account'].account.attributes.team_identifier}/custom-language-maps/phrase`,
        payload
      )

      return response
    } catch (err) {
      throw new Error(JSON.stringify(err))
    }
  },

  populateSourceLanguageMap({ commit }, payload) {
    commit('setMappingSource', payload.data.attributes.source || {})
  },

  populateTargetLanguageMap({ commit }, payload) {
    commit('setMappingTarget', payload.data.attributes.target || {})
  },

  saveSourceLanguageMapRow({ commit }, payload) {
    commit('setMappingSourceValue', payload)
  },

  saveTargetLanguageMapRow({ commit }, payload) {
    commit('setMappingTargetValue', payload)
  },

  deleteSourceLanguageMapRow({ commit }, payload) {
    commit('deleteSourceMappingValues', payload)
  },

  deleteTargetLanguageMapRow({ commit }, payload) {
    commit('deleteTargetMappingValues', payload)
  }
}

const getters = {
  connectionSuccessful(state) {
    return (
      state.steps[0].complete &&
      state.steps[1].complete &&
      state.steps[2].complete &&
      state.steps[3].webhook &&
      state.steps[3].provider
    )
  },
  languageMappingCreated(state) {
    return state.steps[1].complete
  },
  workflowCreated(state) {
    return state.steps[2].complete
  },
  webhookCreated(state) {
    return state.steps[3].webhook
  },
  providerCreated(state) {
    return state.steps[3].provider
  }
}

export const phraseConnector = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
