import ApiService from '@/api/new/services/api.service'

const state = {
  G2State: ''
}

const mutations = {
  setG2State(state, G2State) {
    state.G2State = G2State
  }
}

const actions = {
  async markG2SeenFormAt({ commit }, payload) {
    const data = {
      data: {
        type: 'users',
        attributes: {
          g2: {
            seen_form_at: payload
          }
        }
      }
    }
    await ApiService.post(`user/meta`, data)
      .then((res) => {})
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getG2State({ commit, state, dispatch }) {
    if (!state.G2State) await dispatch('fetchG2State')
  },
  async fetchG2State({ commit }) {
    await ApiService.post(`g2-state`)
      .then((res) => {
        commit('setG2State', res.data.data.attributes.state)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const G2Review = {
  namespaced: true,
  state,
  actions,
  mutations
}
