import ApiEasycatService from '@/api/new/services/api.easycat.service'

const state = {
  currentTm: {
    source_language: '',
    target_languages: [],
    value: ''
  },
  currentTb: null,
  tmSegments: null,
  tmLanguages: {
    sources: [],
    targets: []
  },
  tbLanguages: [],
  tmPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  },
  tbPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  }
}

const mutations = {
  setTMs(state, tms) {
    state.translationMemories = tms
  },
  setTBs(state, tbs) {
    state.termBanks = tbs
  },
  setCurrentTm(state, tm) {
    state.currentTm = tm
  },
  setCurrentTb(state, tb) {
    state.currentTb = tb
  },
  setTmSegments(state, segments) {
    state.tmSegments = segments
  },
  setTbSegments(state, segments) {
    state.tbSegments = segments
  },
  setTmPages(state, tmPages) {
    state.tmPages.current = tmPages.current_page
    state.tmPages.total = tmPages.last_page
    state.tmPages.totalSegments = tmPages.total
    state.tmPages.pageSize = tmPages.per_page
  },
  setTbPages(state, tbPages) {
    state.tbPages.current = tbPages.current_page
    state.tbPages.total = tbPages.last_page
    state.tbPages.totalSegments = tbPages.total
    state.tbPages.pageSize = tbPages.per_page
  },
  setTmSegmentsPages(state, tmPages) {
    state.tmPages = tmPages
  },
  setTbSegmentsPages(state, tbPages) {
    state.tbPages = tbPages
  },
  setTmLanguages(state, lang) {
    state.tmLanguages.sources = lang.source_languages
    state.tmLanguages.targets = lang.target_languages
  },
  setTbLanguages(state, lang) {
    state.tbLanguages = lang
  }
}

const actions = {
  // Translation Memories
  async getStateTmLanguages({ state, dispatch }) {
    if (
      state.tmLanguages.sources.length === 0 ||
      state.tmLanguages.targets.length === 0
    )
      await dispatch('getTmLanguages')
  },
  async getTmLanguages({ commit, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/available-languages/all`
    )
      .then((res) => {
        commit('setTmLanguages', res.data.data.attributes)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterTm({ state, commit, rootGetters }, payload) {
    const page = payload.current || state.tmPages.current
    const perPage = state.tmPages.pageSize
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/filter?page=${page}&perPage=${perPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = {
          current: res.data.current_page,
          total: res.data.last_page,
          totalSegments: res.data.total,
          pageSize: res.data.per_page
        }
        commit('setTmSegments', segments)
        commit('setTmSegmentsPages', pages)
        commit('setCurrentTm', {
          value: payload.attributes.value,
          source_language: payload.attributes.source_language,
          target_languages: [...payload.attributes.target_languages]
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateTmSegment({ rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memory_segments',
        attributes: {
          ...payload.value
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${payload.tmId}/translation-memory-segments/${payload.segmentId}`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async importTm({ rootGetters }, payload) {
    const data = new FormData()
    data.append('data[type]', 'translation_memory')
    data.append(`data[attributes][name]`, payload.name)
    for (let y = 0; y < payload.files.length; y++) {
      data.append('data[attributes][files][]', payload.files[y])
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/import-from-csv`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  // Term Banks
  async getTbLanguages({ state, dispatch }) {
    if (state.tbLanguages.length === 0) await dispatch('tbLanguages')
  },
  async tbLanguages({ commit, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/languages`
    )
      .then((res) => {
        const langs = res.data
        commit('setTbLanguages', langs)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterTb({ state, commit, rootGetters }, payload) {
    const page = payload.current || state.tbPages.current
    const perPage = state.tbPages.pageSize
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          value: payload.value
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/filter?page=${page}&perPage=${perPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = {
          current: res.data.current_page,
          total: res.data.last_page,
          totalSegments: res.data.total,
          pageSize: res.data.per_page
        }
        commit('setTbSegments', segments)
        commit('setTbSegmentsPages', pages)
        commit('setCurrentTb', payload)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async importTb({ rootGetters }, payload) {
    const data = new FormData()
    data.append('data[type]', 'term_banks')
    data.append(`data[attributes][name]`, payload.name)
    for (let y = 0; y < payload.files.length; y++) {
      data.append('data[attributes][files][]', payload.files[y])
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/import-from-csv`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async updateTerm({ rootGetters }, payload) {
    const data = {
      data: {
        type: 'term',
        attributes: {
          value: payload.value
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/${payload.termBankId}/terms/${payload.termId}`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  }
}
const getters = {}

export const langAssets = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
