import ApiService from '@/api/new/services/api.service'

const state = {
  conversation: {}
}

const mutations = {
  setConversation(state, conversation) {
    state.conversation = conversation
  }
}

const actions = {
  async getTaskConversation({ commit, rootGetters }, { id, target }) {
    await commit('setConversation', [])
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${id}/${target}/conversations`
    )
      .then(async (res) => {
        const chat = res.data.data
        await commit('setConversation', chat)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async sendConversationMessage(
    { dispatch, rootGetters },
    { id, target, subject, message }
  ) {
    const data = {
      data: {
        type: 'conversation',
        attributes: {
          subject: subject,
          message: message
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${id}/${target}/conversations`,
      data
    )
      .then(async () => {
        await dispatch('getTaskConversation', { id, target })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async readMessage({ dispatch, rootGetters }, { id, target }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${id}/${target}/read`
    )
      .then(async () => {
        await dispatch('getTaskConversation', { id, target })
        await dispatch('account/getCustomerAccountMessages', null, {
          root: true
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  showChat(state) {
    return (
      state.conversation && state.conversation.attributes.suppliers.length > 0
    )
  }
}

export const conversation = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
