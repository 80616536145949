import i18n from '@/i18n'

const defaultState = () => {
  return {
    showNotification: false,
    notification: {}
  }
}

const state = defaultState()

const mutations = {
  setNotification(state, payload) {
    state.notification = payload
  },
  openNotification(state) {
    state.showNotification = true
  },
  closeNotification(state) {
    state.showNotification = false
    state.notification = {}
  }
}

const actions = {
  handleNotification({ commit }, showNotification) {
    commit('setNotification', showNotification)
    commit('openNotification')
  },
  getNotification({ commit, dispatch }, payload) {
    const showNotification = {
      title: i18n.t('shared.toast.info.title'),
      ...payload
    }
    commit('closeNotification')
    dispatch('handleNotification', showNotification)
  }
}

export const chatNotification = {
  namespaced: true,
  state,
  actions,
  mutations
}
