import ApiService from '@/api/new/services/api.service'

const state = {
  allFolders: []
}

const mutations = {
  setAllFolders(state, folders) {
    state.allFolders = folders
  }
}

const actions = {
  async getFolders({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/folders`
    )
      .then(async (res) => {
        let allFolders = res.data.data
        commit('setAllFolders', allFolders)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  limitFolderList: (state) => (amount) => {
    return state.allFolders.slice(0, amount)
  }
}

export const folder = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
