import ApiLibraryService from '@/api/new/services/api.library.service'
import i18n from '@/i18n'
import UtilsFunction from '@/utils/index'

const state = {
  libraries: [],
  pages: {
    current: 0,
    total: 0,
    perPage: 0
  }
}

const mutations = {
  setLibraries(state, { libraries, currentPage }) {
    if (state.libraries.length > 0 && currentPage > 1) {
      state.libraries = state.libraries.concat(libraries)
    } else {
      state.libraries = libraries
    }
  },
  setPages(state, pages) {
    state.pages.current = pages.current_page
    state.pages.total = pages.last_page
    state.pages.perPage = pages.per_page
  }
}

const actions = {
  async getStateLibraries({ dispatch, state }, { currentPage, perPage, type }) {
    if (state.libraries.length === 0)
      await dispatch('getLibraries', { currentPage, perPage, type })
  },
  async getLibraries({ commit, rootGetters }, { currentPage, perPage, type }) {
    await ApiLibraryService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries`,
      {
        params: {
          page: currentPage,
          perPage,
          'filters[type][0]': type.caas ? 'caas' : '',
          'filters[type][1]': type.string ? 'string' : ''
        }
      }
    )
      .then((res) => {
        const libraries = res.data.data
        const pages = res.data.meta
        commit('setLibraries', { libraries, currentPage })
        commit('setPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createLibrary({ dispatch, state, rootGetters }, payload) {
    let data = {
      data: {
        type: 'library',
        attributes: {
          ...payload
        }
      }
    }
    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries`,
      data
    )
      .then(async () => {
        await dispatch('getLibraries', {
          currentPage: state.pages.current,
          perPage: state.pages.perPage,
          type: { caas: true, string: true }
        })
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_created', {
              name: payload.name
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createFileLibrary({ dispatch, state, rootGetters }, payload) {
    const data = new FormData()
    data.append('data[type]', 'library')
    data.append('data[attributes][name]', payload.name)
    data.append('data[attributes][source_language]', payload.source_language)
    data.append('data[attributes][description]', payload.description)
    for (let i = 0; i < payload.target_languages.length; i++) {
      data.append(
        `data[attributes][target_languages][${i}]`,
        payload.target_languages[i]
      )
    }
    if (payload.file.length > 0) {
      data.append('data[attributes][file]', payload.file[0])
    }
    await ApiLibraryService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/new`,
      data
    )
      .then(async () => {
        await dispatch('getLibraries', {
          currentPage: state.pages.current,
          perPage: state.pages.perPage,
          type: { caas: true, string: true }
        })
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_created', {
              name: payload.name
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  async addLanguage(
    { dispatch, state, rootState, rootGetters },
    { id, target }
  ) {
    const data = {
      data: {
        type: 'library-language',
        attributes: {
          target_languages: [target]
        }
      }
    }
    await ApiLibraryService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}/languages`,
      data
    )
      .then(async () => {
        await dispatch('getLibraries', {
          currentPage: state.pages.current,
          perPage: state.pages.perPage,
          type: { caas: true, string: true }
        })
        const languageName = UtilsFunction.formatLanguages(
          target,
          rootState['account'].languages.all
        )
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_add_language', {
              language: languageName
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteLanguage(
    { dispatch, state, rootState, rootGetters },
    { id, language }
  ) {
    await ApiLibraryService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}/languages/${language}`
    )
      .then(async () => {
        await dispatch('getLibraries', {
          currentPage: state.pages.current,
          perPage: state.pages.perPage,
          type: { caas: true, string: true }
        })
        const languageName = UtilsFunction.formatLanguages(
          language,
          rootState['account'].languages.all
        )
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_remove_language', {
              language: languageName
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteLibrary({ dispatch, state, rootGetters }, { id, name }) {
    await ApiLibraryService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/libraries/${id}`
    )
      .then(async () => {
        await dispatch('getLibraries', {
          currentPage: state.pages.current,
          perPage: state.pages.perPage,
          type: { caas: true, string: true }
        })
        await dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.library_remove', {
              library: name
            })
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const libraries = {
  namespaced: true,
  state,
  actions,
  mutations
}
