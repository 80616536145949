import ApiService from '@/api/new/services/api.service'

const state = {
  languageList: {},
  discountList: {}
}

const mutations = {
  setLanguageList(state, lang) {
    state.languageList = lang
  },
  setDiscountList(state, discounts) {
    state.discountList = discounts
  }
}

const actions = {
  async getPriceList({ commit, rootGetters }, { listType }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/price-list/${listType}`
    )
      .then((res) => {
        const languages = res.data.data.attributes.languages
        const discounts = res.data.data.attributes.discounts
        commit('setLanguageList', languages)
        commit('setDiscountList', discounts)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updatePriceList({ commit, rootGetters }, { listType, languages }) {
    const data = {
      data: {
        type: `${listType}-price-list`,
        attributes: {
          languages
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/price-list/${listType}`,
      data
    )
      .then((res) => {
        const languages = res.data.data.attributes.languages
        commit('setLanguageList', languages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateDiscountList({ dispatch, rootGetters }, { discounts }) {
    const data = {
      data: {
        type: 'translation-price-list',
        attributes: {
          discounts
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/price-list/translation`,
      data
    )
      .then(() => {
        dispatch('getPriceList', { listType: 'translation' })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {}

export const pricing = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
