import ApiService from '@/api/new/services/api.service'

import { validateEmail } from '@/helpers/validation'
import i18n from '@/i18n'

const state = {
  billing: {},
  paymentSecret: '',
  billingPortalBalance: 0,
  balanceHistory: [],
  ballancePagination: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  paymentHistory: [],
  paymentPagination: {
    first: null,
    last: null,
    next: null,
    prev: null
  }
}

const mutations = {
  setBilling(state, billing) {
    state.billing = billing
  },
  setPaymentSecret(state, paymentSecret) {
    state.paymentSecret = paymentSecret
  },
  setBillingPortalBalance(state, balance) {
    state.billingPortalBalance = balance
  },
  setBalanceHistory(state, balance) {
    let combined = [...balance, ...state.balanceHistory]
    const sortDate = combined.sort(
      (a, b) =>
        new Date(b.attributes.created_at).getTime() -
        new Date(a.attributes.created_at).getTime()
    )
    state.balanceHistory = sortDate
  },
  setPaymentPagination(state, pagination) {
    state.paymentPagination = pagination
  },
  setPaymentHistory(state, billing) {
    let combined = [...billing, ...state.paymentHistory]
    const sortDate = combined.sort(
      (a, b) =>
        new Date(b.attributes.created_at).getTime() -
        new Date(a.attributes.created_at).getTime()
    )
    state.paymentHistory = sortDate
  },
  clearPaymentHistory(state) {
    state.paymentHistory = []
  },
  setBallancePagination(state, pagination) {
    state.ballancePagination = pagination
  }
}

const actions = {
  async getStateBilling({ dispatch, state }) {
    if (Object.keys(state.billing).length === 0) await dispatch('getBilling')
  },
  async getBilling({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/billing`
    )
      .then((res) => {
        const billing = res.data.data
        commit('setBilling', billing)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateBilling({ dispatch, commit, rootGetters }, billing) {
    const data = {
      data: {
        type: 'billing',
        attributes: {
          ...billing.attributes
        }
      }
    }

    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/billing`,
      data
    )
      .then(async (res) => {
        await dispatch('getBilling')
      })
      .catch((err) => {
        if (err.response) {
          let billingInfoMissing =
            Object.keys(err.response.data.errors).length > 0
          if (billingInfoMissing) {
            dispatch(
              'toast/warning',
              {
                message: i18n.t(
                  'customer.toast.warning.incomplete_billing_info'
                )
              },
              { root: true }
            )
          }
        }
        throw new Error(JSON.stringify(err))
      })
  },
  async updateDefaultBilling({ dispatch, rootGetters }, { billingMethodId }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/billing/methods/${billingMethodId}/default`
    )
      .then(async () => {
        await dispatch('getBilling')
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.saved_title'),
            message: i18n.t('customer.toast.success.card_updated')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getPaymentSecret({ commit, rootGetters }, { methodType }) {
    const data = {
      data: {
        type: 'setup_intent',
        attributes: {
          type: methodType
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/payments/secret`,
      data
    )
      .then((res) => {
        const secret = res.data.data.attributes.client_secret
        commit('setPaymentSecret', secret)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createBillingMethod({ dispatch, rootGetters }, { stripeToken }) {
    const data = {
      data: {
        type: 'billing_method',
        attributes: {
          method: 'digital',
          payment_method_token: stripeToken
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/billing/methods`,
      data
    )
      .then(async () => {
        await dispatch('getBilling')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteBillingMethod({ dispatch, rootGetters }, { id }) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/billing/methods/${id}`
    )
      .then(async () => {
        await dispatch('getBilling')
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.remove_payment_method') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async viewBillingPortal({ rootGetters }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/billing-portal`
    )
      .then(async (res) => {
        window.location.href = res.data.data.attributes.url
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getBillingPortal({ commit, dispatch, state }) {
    await dispatch('getStateBilling')
    const creditBalance = state.billing.attributes.credit_balance
    const newCreditBalance = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Math.abs(creditBalance) / 100)
    commit('setBillingPortalBalance', newCreditBalance)
  },
  async getBalanceHistory({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/wallet/history?limit=5`
    )
      .then(async (res) => {
        const blanaceHistory = res.data.data
        const pagintation = res.data.links
        commit('setBalanceHistory', blanaceHistory)
        commit('setBallancePagination', pagintation)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async loadMoreBalanceHistory({ commit, rootGetters }, { url }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/wallet/history?starting_after=${url}&limit=5`
    )
      .then(async (res) => {
        const blanaceHistory = res.data.data
        const pagintation = res.data.links
        commit('setBalanceHistory', blanaceHistory)
        commit('setBallancePagination', pagintation)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getPaymentHistory({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/billing/history?limit=5`
    )
      .then(async (res) => {
        const paymentHistory = res.data.data
        const pagintation = res.data.links
        commit('setPaymentHistory', paymentHistory)
        commit('setPaymentPagination', pagintation)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async loadMorePaymentHistory({ commit, rootGetters }, { url }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/billing/history?starting_after=${url}&limit=5`
    )
      .then(async (res) => {
        const paymentHistory = res.data.data
        const pagintation = res.data.links
        commit('setPaymentHistory', paymentHistory)
        commit('setPaymentPagination', pagintation)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSubscription({ dispatch, rootGetters }, newSubscription) {
    const data = {
      data: {
        type: 'billing',
        attributes: {
          subscription: {
            tiers: [{ ...newSubscription }]
          }
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/billing/`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.congrats_title'),
            message: i18n.t('customer.toast.success.upgrade')
          },
          { root: true }
        )
        await dispatch('getBilling')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  isEmailValid: () => (email) => {
    return validateEmail(email)
  }
}

export const payment = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
