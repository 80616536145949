import ApiService from '@/api/new/services/api.service'

const defaultState = () => {
  return {
    contentPillars: [],
    currentPillar: {},
    clusters: [],
    currentCluster: {},
    pages: [],
    keywords: [],
    generatedPages: [],
    selectedPages: [],
    selectedLanguages: [],
    results: [],
    selectedResults: [],
    storeResults: [],
    pendingPages: [],
    currentContentSettings: {}
  }
}

const state = defaultState()

const mutations = {
  setPillars(state, pillars) {
    state.contentPillars = pillars
  },
  setCurrentPillar(state, pillar) {
    state.currentPillar = pillar
  },
  setClusters(state, clusters) {
    state.clusters = clusters
  },
  setCurrentCluster(state, cluster) {
    state.currentCluster = cluster
  },
  setPages(state, pages) {
    state.pages = pages
  },
  setKeywords(state, keywords) {
    state.keywords = keywords
  },
  clearKeywords(state) {
    state.keywords = []
  },
  setGeneratedPages(state, pages) {
    state.generatedPages = pages
  },
  updateGeneratedPage(state, { i, headline }) {
    const newState = [...state.generatedPages]
    newState.splice(i, 1, headline)
    state.generatedPages = newState
  },
  updateResultPage(state, { id, headline }) {
    const newState = [...state.results]
    const targetResult = newState.find((r) => r.id === id)
    targetResult.display_name = headline
    targetResult.name = headline.toLowerCase().replace(/\s+/g, '-')
    state.results = newState
  },
  setSelectedResults(state, results) {
    state.selectedResults = results
  },
  setSelectedPages(state, pages) {
    state.selectedPages = pages
  },
  setSelectedLanguages(state, languages) {
    state.selectedLanguages = languages
  },
  addResult(state, result) {
    state.results = [...state.results, result]
  },
  clearCreateContentFlow(state) {
    state.selectedPages = []
    state.selectedLanguages = []
    state.results = []
    state.generatedPages = []
    state.selectedResults = []
  },
  storeResults(state, results) {
    state.storeResults = results
  },
  setPendingPages(state, pages) {
    state.pendingPages = pages
  },
  setCurrentContentSettings(state, settings) {
    state.currentContentSettings = settings
  },
  clearContentPillars(state) {
    const { contentPillars } = state
    const newState = { ...defaultState(), contentPillars }
    Object.assign(state, newState)
  }
}

const actions = {
  async getStatePillars({ dispatch, state }) {
    if (state.contentPillars.length === 0) await dispatch('getPillars')
  },
  async getPillars({ commit, rootGetters }) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.get(`teams/${teamName}/content-pillars/`)
      .then(async (res) => {
        const pillars = res.data.data
        await commit('setPillars', pillars)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async savePillar({ dispatch, rootGetters }, payload) {
    const teamName = rootGetters['workspace/currentAccountId']
    const data = {
      data: {
        type: 'content_pillars',
        attributes: {
          ...payload,
          type: 'automatic'
        }
      }
    }
    await ApiService.post(`teams/${teamName}/content-pillars/`, data)
      .then(async () => {
        await dispatch('getPillars')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updatePillar({ dispatch, rootGetters }, payload) {
    const teamName = rootGetters['workspace/currentAccountId']
    const data = {
      data: {
        type: 'content_pillars',
        attributes: {
          ...payload.data
        }
      }
    }
    await ApiService.put(
      `teams/${teamName}/content-pillars/${payload.id}`,
      data
    )
      .then(async () => {
        await dispatch('getPillars')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getPillarById({ dispatch, commit, rootGetters }, id) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.get(`teams/${teamName}/content-pillars/${id}`)
      .then(async (res) => {
        const pillar = res.data.data
        await commit('setCurrentPillar', pillar)
        await dispatch('getClustersByPillarId')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getClustersByPillarId({ state, commit, rootGetters }) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.get(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters`
    )
      .then(async (res) => {
        const clusters = res.data.data
        await commit('setClusters', clusters)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getClusterById({ state, commit, dispatch, rootGetters }, clusterId) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.get(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${clusterId}`
    )
      .then(async (res) => {
        const cluster = res.data.data
        await commit('setCurrentCluster', cluster)
        await dispatch('getPagesByClusterId', clusterId)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getPagesByClusterId({ state, commit, rootGetters }, clusterId) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.get(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${clusterId}/content-pages`
    )
      .then(async (res) => {
        const pages = res.data.data
        await commit('setPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async generateKeywords({ commit, rootGetters }, payload) {
    const teamName = rootGetters['workspace/currentAccountId']
    const data = {
      data: {
        type: 'content_pillars',
        attributes: {
          description: payload.description,
          keywords_number: 4
        }
      }
    }
    await ApiService.post(
      `teams/${teamName}/content-pillars/generate-keywords`,
      data
    )
      .then(async (res) => {
        const keywords = res.data
        await commit('setKeywords', keywords)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async generatePages(
    { state, dispatch, commit, rootGetters },
    { suggestions, contentType }
  ) {
    const teamName = rootGetters['workspace/currentAccountId']
    const data = {
      data: {
        type: 'content_pages',
        attributes: {
          suggestions_number: suggestions,
          content_type: contentType
        }
      }
    }
    await ApiService.post(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages/generate-page-titles`,
      data
    )
      .then(async (res) => {
        const pages = res.data
        await commit('setGeneratedPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async generateResults(
    { state, dispatch, commit, rootGetters },
    { contentType, wordLimit, charLimit }
  ) {
    const teamName = rootGetters['workspace/currentAccountId']

    const getResults = async (page) => {
      const data = {
        data: {
          type: 'content_pages',
          attributes: {
            display_name: page,
            ...(wordLimit > 0
              ? { words_count: wordLimit }
              : { chars_count: charLimit }),
            status: 'draft',
            type: contentType,
            languages: state.selectedLanguages
          }
        }
      }
      const results = await ApiService.post(
        `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages`,
        data
      )
      return results
    }

    const promises = state.selectedPages.map(
      async (page) => await getResults(page)
    )
    const results = await Promise.allSettled(promises)
    results.map((result) => {
      commit('addResult', result.value.data.data)
    })
  },
  async storeResults({ state, dispatch, commit, rootGetters }, { startPages }) {
    const teamName = rootGetters['workspace/currentAccountId']
    const resultsToDestroy = state.results.filter(
      (r) => !state.selectedResults.some((rs) => rs.id === r.id)
    )

    const storeResult = async (result) => {
      const { words_count, chars_count, ...rest } = result
      const data = {
        data: {
          type: 'content_pages',
          attributes: {
            ...rest,
            ...(words_count
              ? { words_count: words_count }
              : { chars_count: chars_count })
          }
        }
      }
      const updatedResults = await ApiService.put(
        `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages/${result.id}`,
        data
      )
      if (startPages) {
        await dispatch('startPage', result.id)
      }
      return updatedResults
    }

    const deleteResult = async (result) => {
      const deletedResults = await ApiService.delete(
        `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages/${result.id}`
      )
      return deletedResults
    }

    const updatePromises = state.selectedResults.map(
      async (r) => await storeResult(r)
    )

    const deletePromises = resultsToDestroy.map(
      async (r) => await deleteResult(r)
    )
    const updates = await Promise.allSettled(updatePromises)
    const deleted = await Promise.allSettled(deletePromises)
    commit('storeResults', { updates: [...updates], deleted: [...deleted] })
    commit('setPendingPages', state.selectedResults)
    await dispatch('getClustersByPillarId')
    await dispatch('getClusterById', state.currentCluster.id)
    commit('clearCreateContentFlow')
  },
  async deletePage({ state, dispatch, rootGetters }, id) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.delete(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages/${id}`
    )
      .then(async (res) => {
        await dispatch('getClustersByPillarId')
        await dispatch('getClusterById', state.currentCluster.id)
      })
      .catch((e) => {
        throw new Error(JSON.stringify(e))
      })
  },
  async updatePageStatus(
    { state, dispatch, rootGetters },
    { newStatus, pageId }
  ) {
    const teamName = rootGetters['workspace/currentAccountId']
    const data = {
      data: {
        type: 'content_pages',
        attributes: {
          status: newStatus
        }
      }
    }
    await ApiService.put(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages/${pageId}`,
      data
    )
      .then(async (res) => {
        await dispatch('getClustersByPillarId')
        await dispatch('getClusterById', state.currentCluster.id)
      })
      .catch((e) => {
        throw new Error(JSON.stringify(e))
      })
  },
  async startPage({ state, dispatch, rootGetters, commit }, pageId) {
    const teamName = rootGetters['workspace/currentAccountId']
    await ApiService.post(
      `teams/${teamName}/content-pillars/${state.currentPillar.id}/content-clusters/${state.currentCluster.id}/content-pages/${pageId}/start-copyediting`
    )
      .then(async (res) => {
        if (res.data.data.attributes) {
          commit('tasks/setBillingAttributes', res.data.data, { root: true })
          commit('setPendingPages', [{ id: pageId }])
        }
        await dispatch('getClustersByPillarId')
        await dispatch('getClusterById', state.currentCluster.id)
      })
      .catch((e) => {
        throw new Error(JSON.stringify(e))
      })
  }
}

const getters = {
  currentPillarName(state) {
    return state.currentPillar.display_name
  }
}

export const contentPillars = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
